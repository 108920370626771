<template>
    <div v-if="sociallinks && sociallinks.length > 0">
        <ul class="list-inline fs-4 m-0">
            <li v-for="(item, index) in sociallinks" class="list-inline-item" :class="{ 'ms-2': index > 0 }">
                <a :href="item.link" target="_blank" :title="item.title" class="link-light">
                    <FontAwesomeIcon :icon="item.icon.split(' ')" class="icon alt"/>
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
const { $pb } = useNuxtApp()

const sociallinks = await $pb.collection('social_links').getFullList({
    sort: '-title',
})
</script>