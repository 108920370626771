<template>
    <div class="footer py-3 text-bg-dark border-top border-primary">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-12 col-md-6 text-center text-md-start">
                    © {{ new Date().getFullYear() }} Hendrik Herpers
                </div>
                <div class="col-12 col-md-6 text-center text-md-end">
                    <MenuSocial />
                </div>
            </div>
        </div>
    </div>
</template>