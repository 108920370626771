<template>
    <a class="link-light fs-4" data-bs-toggle="offcanvas" data-bs-target="#headerMainMenu" aria-controls="headerMainMenu">
        <FontAwesomeIcon :icon="['fas', 'bars']" class="icon alt"/>
    </a>

    <div class="main-menu offcanvas offcanvas-top text-bg-dark" tabindex="-1" id="headerMainMenu"
        aria-labelledby="headerMainMenuLabel">
        <div class="offcanvas-header py-4">
            <div class="container">
                <div class="row">
                    <a href="#" class="p-0 fs-4 link-light" data-bs-dismiss="offcanvas">
                        <FontAwesomeIcon :icon="['far', 'circle-xmark']" class="icon alt" />
                    </a>
                </div>
            </div>
        </div>
        <div class="offcanvas-body container">
            <div class="row h-100 justify-content-center align-items-center">
                <ul class="nav flex-column w-auto p-0">
                    <li v-for="item in links" class="nav-item">
                        <a class="nav-link link-underline link-underline-opacity-0 border-start ps-5 border-3"
                            :href="item.link">
                            {{ item.title }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const links = [
    {
        title: 'Über mich',
        link: '#aboutme'
    },
    {
        title: 'Skillset',
        link: '#skillset'
    },
    {
        title: 'Projekte',
        link: '#projects'
    },
    {
        title: 'Social',
        link: '#social'
    },
]

useHead({
    bodyAttrs: {
        'data-bs-spy': 'scroll',
        'data-bs-target': '#headerMainMenu'
    }
})
</script>